import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"



const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/campus/',
      crumbLabel: '筑波キャンパスについて'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

    return(
      <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("組織図")} lang={lang} />
      
      <ContentLayout lang={lang}>
        <h1>{t("組織図")}</h1>
        <ul className="construction">
          <li>
            <h2>{t("理事長・理事・監事")}</h2>
            <ul>
              <li>
                <h3>
                  <External 
                    href={"https://"+t("web.brc.riken.jp/ja/")}
                    >
                    {t("バイオリソース研究センター")}
                    </External>
                </h3>
              </li>
              <li>
                <h3>{t("筑波事業所")}</h3>
                <ul>
                  <li>
                    <h4>{t("研究支援部")}</h4>
                    <ul>
                      <li><h5>{t("総務課")}</h5></li>
                      <li><h5>{t("施設課")}</h5></li>
                      <li><h5>{t("人事課")}</h5></li>
                      <li><h5>{t("経理課")}</h5></li>
                    </ul>
                  </li>
                  <li><h4>{t("バイオリソース研究推進室")}</h4></li>
                  <li><h4>{t("情報システム室")}</h4></li>
                  <li><h4>{t("安全管理室")}</h4></li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </ContentLayout>
    </Layout>
    )
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`